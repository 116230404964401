<template>
   <EditorItem :item="vItem" :addFormClasses="addFormClasses"/>
</template>
<script setup>    
    import EditorItem from './BaseItem.vue';
    import { ref, defineEmits, watch, onBeforeUnmount } from 'vue';
    import InputEditorsItem  from './inputEditorsItem.ts';
    
    const emit = defineEmits(['change']);
    const props = defineProps({
        modelValue: null,
        item:Object,
        for:String,
        addFormClasses:{
            type:Boolean,
            default:false
        },
        valueSetter: Function,
        valueGetter: Function
    });

    const vItem = ref(null);
    const watcherCancelFns = [];
    const model = defineModel();

    watch(
        () => vItem.value?.value,
        (newValue) => {
            if (typeof newValue === 'undefined') {
                return;
            }
            model.value = newValue;
        }
    );

    function updateItem2() {
        props.item.PropertyName = props.item.PropertyName ?? '' ;
        vItem.value = new InputEditorsItem(props);
        if(model.value) {
            vItem.value.value = model.value;
        }
    }
    
    watcherCancelFns.push(watch(() => props.item, () => { updateItem2(); }));
    watcherCancelFns.push(watch(() => props.item?.PropertyName, () => { updateItem2(); }));

    updateItem2();    

    onBeforeUnmount(() => {
        watcherCancelFns.forEach(unwatch => {
            unwatch();
        });
    });
</script>
